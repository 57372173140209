import React, { Fragment } from 'react'
import NavBottom from './NavBottom'
import AllBlogItems from './Blog/AllBlogItems'
import MainHeader from '../../components/header/MainHeader'
import InnerBanner from '../../components/InnerBanner/InnerBanner'


const Dashboard = () => {
  return (
    <Fragment>
      <div className='main-page-wrapper'>
         {/* <AdminNav /> */}
      <MainHeader />
      <div className='theme-inner-banner'>
          <InnerBanner
            setPage={'/'}
            pageName='Home'
            intro='Dashboard'
            currentPage='Manage'
          />
        </div>

      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='admin-dashboard-section-title'>
              <AllBlogItems />
            </div>
            
            <div className='bottom-footer p-3 fixed-bottom'>
              <NavBottom />
            </div>
          </div>
        </div>
      </div>

      </div>
     
    </Fragment>
  )
}

export default Dashboard
