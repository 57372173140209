import React, {Fragment} from 'react';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';

const FaqContent = [
    {
        title: 'How many bedrooms does Flesk River Apartments have?',
        desc: `Flesk River Apartments has 2 bedrooms`,
        expand: 'a'
    }, {
        title: 'What are the check in and check out times at Flesk River Apartments?',
        desc: `Check in times are from 16:00 and check out times are from 10:00`,
        expand: 'b'
    }, {
        title: 'How many guests can sleep at Flesk River Apartments?',
        desc: `5 Guests`,
        expand: 'c'
    }, {
        title: 'How far is Flesk River Apartments from Killarney?',
        desc: `Flesk River Apartments is 1.4 km from the centre of Killarney`,
        expand: 'd'
    }, {
        title: 'How much does it cost to stay at Flesk River Apartments?',
        desc: `The prices at Flesk River Apartments may vary depending on you stay (e.g. dates you select, hotel's policy, etc.) See the prices by entering your preferred dates`,
        expand: 'e'
    }
    , {
        title: 'Is Flesk River Apartments popular with families?',
        desc: `Yes, Flesk River Apartments is popular with guests booking family stays`,	
        expand: 'f'
    }
    , {
        title: 'How can I get to Flesk River Apartments from the nearest Airport?',
        desc: `From the nearest airport, you can get to Flesk River Apartments by taxi. 20 minutes from the airport.`,	
        expand: 'g'
    }
]

const FreqAskedQuestion = () => {
    return (
        <Fragment>
            <Accordion
                className="accordion-style-one"
                preExpanded={["a"]}
                allowZeroExpanded>
                {FaqContent.map((item, i) => (
                    <AccordionItem className="accordion-item" key={i} uuid={item.expand}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <button className="accordion-button" type="button">
                                    {item.title}
                                </button>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-body fadeInUp">
                            <p>
                                {item.desc}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Fragment>
    )
}

export default FreqAskedQuestion