import React, { useState, useEffect, Fragment, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import Creatable, { useCreatable } from 'react-select/creatable'
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase.config'
import MainHeader from '../../../components/header/MainHeader'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build'

import Parser from 'html-react-parser'
import { toast } from 'react-toastify'
import Spinner from '../../../components/Spinner/Spinner'
import InnerBanner from '../../../components/InnerBanner/InnerBanner'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ReplaceImage from './ReplaceImage'
import NavBottom from '../NavBottom'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

function AdminBlogDetails () {
  const [loading, setLoading] = useState(true)
  const [blog, setBlog] = useState(false)
  const [formData, setFormData] = useState({
    postTag: ['tag', 'tag'],
    postDuration: '',
    postTitle: '',
    postCategory: ['', ''],
    content: '',
    fade: 'fade-up',
    dataDelay: '100'
  })
  const {
    postTag: postTag,
    postDuration,
    postTitle,
    postCategory: postCategory,
    content,
    fade,
    dataDelay
  } = formData

  const params = useParams()
  const docRef = doc(db, 'blogs', params.id)
  const auth = getAuth()
  const navigate = useNavigate()
  const isMounted = useRef(true)

  const selectOptions = [
    { value: 'Eat&Drink', label: 'Eat and Drink' },
    { value: 'Families', label: 'Families' },
    { value: 'Explore', label: 'Explore' },
    { value: 'Facilities', label: 'Facilities' }
  ]
  const selectTagOptions = [
    { value: 'Eat&Drink', label: 'Eat and Drink' },
    { value: 'Families', label: 'Families' },
    { value: 'Explore', label: 'Explore' },
    { value: 'Facilities', label: 'Facilities' }
  ]

  const handleChangeSelectOption = (selectOptions, event) => {
    console.log(blog)
    console.log(`Option selected:`, selectOptions.value)
    setFormData(blog => ({
      ...blog,
      postCategory: selectOptions
    }))
  }

  const handleChangeSelectTagOption = (field, value) => {
    const loopTags = () => {
      const tags = []
      console.log(tags)
      // forEach loop so that we can remove the __isNew__ property | firebase won't upload with it
      value.forEach(tag => {
        tags.push({ value: tag.value, label: tag.label })
      })
      return tags
    }   

    switch (field) {
      case 'selectTagOptions':
        setFormData(prevState => ({
          ...prevState,
          postTag: loopTags()
        }))
        break
      default:
        break
    }
  }

  const handleEditorState = (event, editor) => {
    const data = editor.getData()
    setFormData({ ...formData, content: data })
  }

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, user => {
        // console.log(auth)
        if (user) {
          setFormData({ ...formData, userRef: user.uid })
        } else {
          navigate('/admin/sign-in')
        }
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [isMounted])

  useEffect(
    () => {
      setLoading(true)
      const fetchListing = async () => {
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          setBlog(docSnap.data())
          setFormData({ ...docSnap.data() })
          console.log('formData:', formData)
          setLoading(false)
        } else {
          // doc.data() will be undefined in this case
          console.error('Error getting document:', docSnap.error)
          toast.error('Error getting document')
        }
      }
      fetchListing()
    },
    [params.id],
    navigate
  )

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    const formDataCopy = {
      ...formData,
      timestamp: serverTimestamp()
    }

    await updateDoc(docRef, formDataCopy)
    setLoading(false)
    toast.success('Post Updated')
    navigate(
      `/blog/${formDataCopy.postCategory.value}/blog-details/${docRef.id}`
    )
  }

  const onMutate = e => {
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }

    // Files
    if (e.target.files) {
      const files = e.target.files
      console.log('prev files', files)
      setFormData(prevState => ({
        ...prevState,
        images: files
      }))
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData(prevState => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <main>
      <Fragment>
        <div className='main-page-wrapper'>
          <MainHeader />
          <div className='theme-inner-banner'>
            <InnerBanner
              setPage={'/admin/dashboard'}
              pageName={'Dashboard'}
              intro={'Editing post: ' + blog.postTitle}
              currentPage='Edit Post'
            />
          </div>

          <div className='blog-details mb-150 lg-mb-100 py-2'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-xxl-12 m-auto'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <article className='blog-meta-three tran3s mt-45'>
                        <Swiper
                          // install Swiper modules
                          modules={[Navigation, Pagination, Scrollbar, A11y]}
                          spaceBetween={50}
                          navigation
                          onSwiper={swiper => console.log(swiper)}
                          onSlideChange={() => console.log('slide change')}
                        >
                          {blog.imgUrls.map((images, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div
                                  className='swiperSlideDiv'
                                  style={{
                                    backgroundImage: `url(${blog.imgUrls[index]})`,
                                    backgroundSize: 'cover',
                                    height: '450px',
                                    width: '815px',
                                    backgroundPosition: 'centre'
                                  }}
                                ></div>
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                        <ReplaceImage />
                        <div className='post-data'>
                          <div className='post-tag'>
                            {/* ---- TODO - convert these into link  ---- */}-{' '}
                            {blog.postDuration}
                          </div>

                          <div>{Parser(blog.content)}</div>
                        </div>
                      </article>
                            {/* Bottom Tag Widget */}
                            <div className='bottom-widget d-sm-flex align-items-center justify-content-between'>
                          <div className='post-tag'>
                            <ul className='d-flex tags style-none pt-10 align-items-center flex-wrap'>
                            <h6 className='mr-1 py-2'>SEO Reference Tags:</h6>
                            {
                              blog.postTag.map((tag, index) => {                                
                                return (
                                  <li className='mr-2 bg-theme' key={index}>
                                    <a href={'https://www.google.ie/search?q=' + tag.value} target='_blank' rel='noreferrer'>{tag.value}</a>                                    
                                  </li>
                                )
                              })
                            }             
                            </ul>
                          </div>
                        </div>
                    </div>
                    {/* <!-- End of Blog Details --> */}
                    <div className='col'>
                      <div className='blog-sidebar '>
                        <div className='container'>
                          <form id='create-post' onSubmit={onSubmit}>
                            <div className='row'>
                              <div className='col-lg-12'>
                                <div className='input-group-meta form-group mb-30'>
                                  <label htmlFor='postTitle'>Title (appended to SEO Title)</label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='postTitle'
                                    value={postTitle}
                                    onChange={onMutate}
                                    placeholder='Enter Title'
                                  />
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='input-group-meta form-group mb-30'>
                                  <label htmlFor='postTag'>SEO Keywords</label>
                                  <Creatable
                                    value={postTag}
                                    id='postTag'
                                    isMulti
                                    options={selectTagOptions}
                                    onChange={value =>
                                      handleChangeSelectTagOption(
                                        'selectTagOptions',
                                        value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='input-group-meta form-group mb-30'>
                                  <label htmlFor='postCategory'>
                                    Post Category
                                  </label>
                                  <Select
                                    value={postCategory}
                                    options={selectOptions}
                                    onChange={handleChangeSelectOption}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='input-group-meta form-group mb-30'>
                                  <label htmlFor='postDuration'>
                                    Post Duration
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='postDuration'
                                    placeholder='4 minute read'
                                    value={postDuration}
                                    onChange={onMutate}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='input-group-meta form-group mb-30'>
                                  <label htmlFor='content'>Content</label>
                                  <CKEditor
                                    id='content'
                                    data={content}
                                    editor={Editor}
                                    onReady={editor => {
                                      // You can store the "editor" and use when it is needed.
                                      console.log(
                                        'My Editor is ready to use!',
                                        editor,                                        
                                      )
                                    }}
                                    config={{
                                      toolbar: [
                                        'heading',
                                        '|',
                                        'bold',
                                        'italic',
                                        'link',
                                        'bulletedList',
                                        'numberedList',
                                        '|',
                                        'outdent',
                                        'indent',
                                        '|',
                                        'blockQuote',
                                        'insertTable',
                                        'mediaEmbed',
                                        'undo',
                                        'redo'
                                      ]
                                    }}
                                    onChange={handleEditorState}
                                  />
                                </div>
                              </div>
                            </div>
                            <button
                              type='submit'
                              className='primaryButton btn-eight ripple-btn'
                            >
                              Edit Post
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NavBottom />
          {/* ---- end main page wrapper ---- */}
        </div>
      </Fragment>
    </main>
  )
}

export default AdminBlogDetails
