import React from 'react'
import CookieConsent from 'react-cookie-consent'

const CookieManagement = () => {
  return (
    <CookieConsent      
      buttonClasses='btn-one ripple-btn cookieInfo '
      containerClasses='col-lg-12 col-md-12 col-sm cookieContainer'
      contentClasses='ml-1 text-capitalize'
      disableStyles={true}
    >
      This website uses cookies to enhance the user experience.{' '}
      
    </CookieConsent>
  )
}

export default CookieManagement
