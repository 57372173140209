import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';


const SocialContent = [
    {
        icon: 'fab fa-facebook-f',
        routerPath: 'https://www.facebook.com/killarneyfleskriverapartments'
    },
    {
        icon: 'fab fa-instagram',
        routerPath: 'https://www.instagram.com/killarneyfleskriverapartments/'
    }
];
const SiteMap = [
    {
        name: 'Home',
        routerPath: '/'
    },  {
        name: 'About Us',
        routerPath: '/about'
    }, {
        name: 'Contact Us',
        routerPath: '/contact-us'
    }, {
        name: 'Blog',
        routerPath: '/blog'
    }
];

const LegalContent = [
     {
        name: 'Privacy policy',
        routerPath: '/privacy'
    }
];

const Footer = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-4 footer-intro mb-40">
            <div className="logo">
              <Link to="/">
                <img src="images/logo/logo_01.png" alt="" width={130} />
              </Link>
            </div>
            <p>
              Killarney Flesk River Apartments trading as SLW Hoteliers is
              operated and managed by The Brehon Hotel. For press enquiries
              please contact The Brehon Hotel.
            </p>
            <ul className="d-flex social-icon style-none">
              {SocialContent.map((val, i) => (
                <li key={i}>
                  <a href={val.routerPath}>
                    <i className={val.icon} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-2 col-sm-4  mb-30 ms-auto">
            <h5 className="footer-title">Site Map</h5>
            <ul className="footer-nav-link style-none">
              {SiteMap.map((val, i) => (
                <li key={i}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </li>
              ))}
              <li>
                <a
                  href="https://secure.killarneyriverapartments.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Book Now
                </a>
              </li>
            </ul>
          </div>

          <div className="col-xl-2 col-lg-3 col-sm-4 mb-30 ms-auto">
            <h5 className="footer-title">Legal</h5>
            <ul className="footer-nav-link style-none">
              {LegalContent.map((val, i) => (
                <li key={i}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    );
}

export default Footer