
import React,{ useState, Fragment} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {Link} from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuthStatus } from '../../hooks/useAuthStatus';
import AdminProfile from './AdminProfile'
import {
  faUtensils,
  faPeopleRoof,
  faBinoculars,
  faHouseCircleCheck,
  faBlog
} from '@fortawesome/free-solid-svg-icons'
import './burger.scss'


function CanvasNavigation() {
  const expand = 'lg'
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { loggedIn, checkingStatus } = useAuthStatus()
  const ExploreKillarney = [
    {
      name: 'Eat & Drink',
      icon: faUtensils,
      routerPath: '/blog/Eat&Drink/'
    },
    {
      name: 'Families',
      icon: faPeopleRoof,
      routerPath: '/blog/Families/'
    },
    {
      name: 'Explore',
      icon: faBinoculars,
      routerPath: '/blog/Explore/'
    },
    {
      name: 'Facilities',
      icon: faHouseCircleCheck,
      routerPath: '/blog/Facilities/'
    },
    {
      name: 'View All',
      icon: faBlog,
      routerPath: '/blog/'
    },
  ]

  return (
    <Fragment>
      <div
        className={show ? "menu opened " : "menu d-lg-none"}
        onClick={handleShow}
      >
        <svg width="50" height="50" viewBox="0 0 100 100">
          <path
            className="line line1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path className="line line2" d="M 20,50 H 80" />
          <path
            className="line line3"
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
      </div>

      <Navbar.Offcanvas
        show={show ? show : undefined}
        onHide={handleClose}
        responsive="lg"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link active>Home</Nav.Link>
            </LinkContainer>

            <NavDropdown
              title="Explore Killarney"
              id={`offcanvasNavbarDropdown-expand-${expand}`}
            >
              {ExploreKillarney.map((val, i) => (
                <LinkContainer key={i} to={val.routerPath}>
                  <NavDropdown.Item>
                    <FontAwesomeIcon icon={val.icon} />
                    <span className="ml-1">{val.name}</span>
                  </NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>
            {/* ---- ./dropdown ---- */}

            <LinkContainer to="/contact-us">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="ml-auto mr-8">
            {loggedIn ? (
              <AdminProfile />
            ) : (
              <Nav.Link
                href="https://secure.killarneyriverapartments.com/"
                className="send-msg-btn text-white"
                target="_blank"
                id="bookNowTracker"
                rel="noreferrer"
              >
                Book Now
              </Nav.Link>
            )}
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Fragment>
  );
}

export default CanvasNavigation;