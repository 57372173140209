import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import MainHeader from '../../components/header/MainHeader'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import BottomPage from '../inner-pages/BottomPage'

const AboutUs = () => {
  return (
    <Fragment>
      <div className='main-page-wrapper'>
        <Helmet>
          <title>Flesk River Apartments - About Us</title>
        </Helmet>
        <MainHeader />

        <div className='theme-inner-banner'>
          <InnerBanner setPage={'/'} pageName={'Home'} intro='About Us' currentPage='About' />
        </div>
        <BottomPage />
      </div>
    </Fragment>
  )
}

export default AboutUs
