import React, { useState, useEffect, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage'
import { doc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../../../firebase.config'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../../../components/Spinner/Spinner'

function EditListing () {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [imageData, setImageData] = useState({
    images: {}
  })

  const { images } = imageData

  const auth = getAuth()
  const navigate = useNavigate()
  const params = useParams()
  const isMounted = useRef(true)

  useEffect(() => {
    setLoading(true)
    const fetchListing = async () => {
      const docRef = doc(db, 'blogs', params.id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setImageData({ ...docSnap.data(), images: docSnap.data().imgUrls })
        console.log('imageData', imageData)
        setLoading(false)
      } else {
        navigate('/')
        toast.error('Error fetching image')
      }
    }

    fetchListing()
  }, [params.id, navigate])

  const onMutate = e => {
    
    // Files
    if (e.target.files) {
      setImageData(prevState => ({
        ...prevState,
        images: e.target.files
      }))
    }
  }

  const onSubmit = async e => {
    e.preventDefault()

    setLoading(true)
    // Store image in firebase
    const storeImage = async image => {
      return new Promise((resolve, reject) => {
        const storage = getStorage()
        const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`
        const storageRef = ref(storage, 'images/' + fileName)
        const uploadTask = uploadBytesResumable(storageRef, image)

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
              default:
                break
            }
          },
          error => {
            reject(error)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
              resolve(downloadURL)
            })
          }
        )
      })
    }

    const imgUrls = await Promise.all(
      [...images].map(image => storeImage(image))
    ).catch(() => {
      setLoading(false)
      toast.error('Images not uploaded')
      return
    })

    const imageDataCopy = {
      ...imageData,
      imgUrls,
      timestamp: serverTimestamp()
    }

    delete imageDataCopy.images

    // Update listing
    const docRef = doc(db, 'blogs', params.id)
    await updateDoc(docRef, imageDataCopy)
    setLoading(false)
    toast.success('Image Updated')
    navigate(0)
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <div className='d-flex flex-row align-items-center'>
      <form id='create-post' onSubmit={onSubmit}>
        <div className='input-group-meta form-group'>
          <input
            className='formInputFile '
            type='file'
            id='images'
            onChange={onMutate}
            max='6'
            accept='.jpg,.png,.jpeg'
            multiple
            required
          />
        </div>

        <div className='p-2'>
          <button className='btn btn-primary' type='submit'>
            Replace Images
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditListing
