import React, { Fragment, useState } from 'react'
import MainNavigation from './MainNavigation'


const MainHeader = () => {
  const [navbar, setNavbar] = useState(false) 

  const toggleMenu = () => {
    if (window.scrollY >= 68) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  window.addEventListener('scroll', toggleMenu)
  return (
    <Fragment>
      <header
        className={
          navbar
            ? 'theme-main-menu sticky-menu theme-menu-four fixed'
            : 'theme-main-menu sticky-menu theme-menu-four'
        }
      >
        <div className='inner-content'>
          <MainNavigation />
        </div>
      </header>
    </Fragment>
  )
}

export default MainHeader
