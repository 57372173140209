import React from 'react'

function RatingBar () {
  return (
    <div className='container'>
      <div className='row row-cols-1 row-cols-lg-3'>
        <div className='col'>
          {' '}
          <div className='codeconSkills staff'>
            <div className='codeconSkillbar staff'>
              <div className='skill-text'>
                <span className='codeconSkillArea'>Staff</span>
              </div>
              <div
                className='skillBar'
                data-percent='91%'
                style={{
                  background: '#7736ff',
                  color: '#000'
                }}
              >
                <span className='PercentText'>9.1</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='codeconSkillbar comfort'>
            <div className='skill-text'>
              <span className='codeconSkillArea'>Comfort</span>
            </div>
            <div
              className='skillBar'
              data-percent='95%'
              style={{
                background: '#02979e'
              }}
            >
              <span className='PercentText'>9.5</span>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='codeconSkillbar facilities'>
            <div className='skill-text'>
              <span className='codeconSkillArea'>Facilities</span>
            </div>
            <div
              className='skillBar'
              data-percent='95%'
              style={{
                background: '#00E8FA'
              }}
            >
              <span className='PercentText'>9.5</span>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='codeconSkillbar value'>
            <div className='skill-text'>
              <span className='codeconSkillArea'>Value for money</span>
            </div>
            <div
              className='skillBar'
              data-percent='95%'
              style={{
                background: '#00E8FA'
              }}
            >
              <span className='PercentText'>9.1</span>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='codeconSkillbar clean'>
            <div className='skill-text'>
              <span className='codeconSkillArea'>Cleanliness</span>
            </div>
            <div
              className='skillBar'
              data-percent='95%'
              style={{
                background: '#7736ff'
              }}
            >
              <span className='PercentText'>9.4</span>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='codeconSkillbar location'>
            <div className='skill-text'>
              <span className='codeconSkillArea'>Location</span>
            </div>
            <div
              className='skillBar'
              data-percent='95%'
              style={{
                background: '#02979e'
              }}
            >
              <span className='PercentText'>9.4</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RatingBar
