import React, { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import MainHeader from '../../components/header/MainHeader'
import BottomPage from '../../views/inner-pages/BottomPage'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { db } from '../../firebase.config'
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import Spinner from '../Spinner/Spinner'

const BlogCategory = () => {
  const [blog, setBlog] = useState(null)
  const [loading, setLoading] = useState(true)
  const params = useParams()
  console.log(params)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const docRef = collection(db, 'blogs')
        const docSnap = await getDocs(docRef)
        console.log(docSnap)

        const queryData = query(
          docRef,
          where('postCategory.value', '==', params.postCategory),
          orderBy('timestamp', 'desc'),
          limit(10)
        )

        const querySnap = await getDocs(queryData)
        const blog = []
        querySnap.forEach(doc => {
          console.log(doc.data())
          return blog.push({
            id: doc.id,
            data: doc.data()
          })
        })
        setBlog(blog)
        console.log(blog)
        setLoading(false)
      } catch (error) {
        toast.error('Could not fetch listings')
        console.log(error)
      }
    }
    fetchListing()
  }, [params.postCategory])

  return (
    <div className='main-page-wrapper'>
      {loading ? (
        <Spinner />
      ) : blog && blog.length > 0 ? (
        <Fragment>
          <Helmet>
            <title>{`${blog[0].data.postCategory.label} | ${blog[0].data.postCategory.value}`}</title>
          </Helmet>

          <div className='theme-inner-banner'>
            <InnerBanner
              setPage={'/'}
              pageName={'Home'}
              intro={`${blog[0].data.postCategory.label}`}
              currentPage='Blog'
            />
          </div>

          <MainHeader />

          <div className='blog-section-three pt-90 mb-150 lg-pt-40 lg-mb-100'>
            <div className='container'>
              <div className='row gx-xxl-5'>
                {blog.map(item => (
                  <Fragment key={item.id}>
                    <div
                      className='col-lg-4 col-sm-6 d-flex'
                      key={item.id}
                      data-aos={item.fade}
                      data-aos-delay={item.dataDelay}
                    >
                      <h1>{item.postTitle}</h1>
                      <article className='blog-meta-one color-two tran3s mt-45'>
                        <figure className='post-img m0'>
                          <Link
                            to={`blog-details/${item.id}`}
                            className='w-100 d-block'
                          >
                            <img
                              src={item.data.imgUrls[0]}
                              alt=''
                              className='w-100 tran4s'
                            />
                          </Link>
                        </figure>

                        <div className='post-data'>
                          <Link
                            to={`blog-details/${item.id}`}
                            className='blog-title'
                          >
                            <h5>{item.data.postTitle}</h5>
                          </Link>
                        </div>
                        {/* /.post-data */}
                        
                        {/* Bottom Tag Widget */}
                        <div className='bottom-widget d-sm-flex align-items-center justify-content-between'>
                          <div className='post-tag'>
                            {/* <ul className='d-flex tags style-none pt-10 align-items-center'>
                            <span className='ml-2 mr-1 py-2'>Tag:</span>
                              {item.data.postTag.map((tag, index) => {
                                return (
                                  <li className='mr-3' key={index}>
                                    {tag.value}
                                  </li>
                                )
                              })}
                            </ul> */}
                          </div>
                        </div>
                        {/* /.bottom-widget */}
                      </article>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <p>No Blogs fround for {params.postCategory}</p>
      )}
      <BottomPage />
    </div>
  )
}

export default BlogCategory
