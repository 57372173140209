import React, { Fragment } from 'react'
import Icon17 from '../../assets/images/icon/icon_17.svg'
import Icon18 from '../../assets/images/icon/icon_18.svg'
import MobileSolid from '../../assets/images/icon/mobile-solid.svg'
import { Link } from 'react-router-dom'

const SocialContent = [
  {
    icon: 'fab fa-facebook-f',
    routerPath: 'https://www.facebook.com/killarneyfleskriverapartments',
    desc: 'Facebook'
  },
  {
    icon: 'fab fa-instagram',
    routerPath: 'https://www.instagram.com/killarneyfleskriverapartments/',
    desc: 'Instagram'
  }
]

const ContactBlock = () => {
  return (
    <Fragment>
      <div className='row'>
        <div className='col-md-4'>
          <div className='address-block-two text-center mb-40 sm-mb-20'>
            <div className='icon d-flex align-items-center justify-content-center m-auto'>
              <img src={Icon17} alt='' />
            </div>
            <h5 className='title'>Our Address</h5>
            <p>
              Muckross Rd, Poulnamuck
              <br />
              Killarney, Co Kerry V93 KX05 
            </p>
          </div>
          {/* /.address-block-two */}
        </div>
        <div className='col-md-4'>
          <div className='address-block-two text-center mb-40 sm-mb-20'>
            <div className='icon d-flex align-items-center justify-content-center m-auto'>
              <img src={Icon18} alt='' />
            </div>
            <h5 className='title'>Contact Info</h5>
            <p>
              Send us a email or give us call at
              <br />
              <a href='tel:353646671543' className='call'>
                (064) 667 1543
              </a>
               or
              <a href='mailto:reservations@fleskriverapartments.com' className='call'>
                Email
              </a>
            </p>
          </div>
          {/* /.address-block-two */}
        </div>
        <div className='col-md-4'>
          <div className='address-block-two text-center mb-40 sm-mb-20'>
            <div className='icon d-flex align-items-center justify-content-center m-auto'>
              <img src={MobileSolid} alt='' />
            </div>
            <h5 className='title'>Social</h5>
            <div className='row'>
              {SocialContent.map((val, i) => (
                <div className='col mb-4' key={i}>
                  <a href={val.routerPath} target='_blank' rel='noreferrer'>
                    <i className={val.icon} /> {val.desc}
                  </a>
                </div>
              ))}
            </div>
            <ul className='d-flex social-icon style-none'></ul>
          </div>
          {/* /.address-block-two */}
        </div>
      </div>
    </Fragment>
  )
}

export default ContactBlock
