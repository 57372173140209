import React, { Fragment } from 'react'
import MainHeader from '../header/MainHeader'
import InnerBanner from '../InnerBanner/InnerBanner'
import BottomPage from '../../views/inner-pages/BottomPage'

const Privacy = () => {
  return (
    <Fragment>
      <div className='main-page-wrapper'>
        <MainHeader />
        <div className='theme-inner-banner'>
          <InnerBanner
            setPage={'/'}
            pageName='Home'
            intro='Privacy Policy'
            currentPage='Privacy'
          />
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className=''>
                <h5>What rights do you have ?</h5>
                <p>
                  As a data subject, you have the following rights under the
                  Data Protection Legislation:
                  <ul>
                    <li>
                      the right of access to personal data relating to you
                    </li>
                    <li>
                      the right to correct any mistakes in your personal data
                    </li>
                    <li>the right to ask us to stop contacting you </li>
                    <li>
                      the right to restrict or prevent your personal data being
                      processed{' '}
                    </li>
                    <li>
                      the right to have your personal data ported to another
                      data controller{' '}
                    </li>
                    <li>the right to erasure and </li>
                    <li>
                      the right to complain to the DPC if you believe we have
                      not handled your personal data in accordance with the Data
                      Protection Legislation{' '}
                    </li>
                  </ul>
                </p>
                <p>
                  These rights are explained in more detail below, but if you
                  have any comments, concerns or complaints about our use of
                  your personal data, please contact us (see ‘How to contact us’
                  below). We will respond to any rights that you exercise within
                  a month of receiving your request, unless the request is
                  particularly complex or cumbersome, in which case we will
                  respond within three months (we will inform you within the
                  first month if it will take longer than one month for us to
                  respond). Where a response is required from us within a
                  particular time period pursuant to Data Protection
                  Legislation, we will respond within that time period.
                </p>
                <h5 className='mt-4'>
                  Right to access to personal data relating to you
                </h5>
                <p>
                  You may ask to see what personal data we hold about you and be
                  provided with:
                </p>
                <ul>
                  <li>
                    a summary of such personal data and the categories of
                    personal data held{' '}
                  </li>
                  <li>
                    details of the purpose for which it is being or is to be
                    processed{' '}
                  </li>
                  <li>
                    {' '}
                    details of the recipients or classes of recipients to whom
                    it is or may be disclosed, including if they are overseas
                    and what protections are used for those oversea transfers
                  </li>
                  <li>
                    {' '}
                    details of the period for which it is held (or the criteria
                    we use to determine how long it is held)
                  </li>
                  <li>
                    {' '}
                    details of your rights, including the rights to
                    rectification, erasure, restriction or objection to the
                    processing
                  </li>
                  <li>
                    {' '}
                    any information available about the source of that data
                  </li>
                  <li>
                    {' '}
                    whether we carry out automated decision-making, or
                    profiling, and where we do, information about the logic
                    involved and the envisaged outcome or consequences of that
                    decision making or profiling and
                  </li>
                  <li>
                    {' '}
                    where your personal data are transferred out of the EEA,
                    what safeguards are in place
                  </li>
                </ul>
                <p>
                  Requests for your personal data must be made to us (see 'How
                  to contact us' below) specifying what personal data you need
                  access to, and a copy will be retained on your personal file.
                  To help us find the information easily, please give us as much
                  information as possible about the type of information you
                  would like to see. If, to comply with your request, we would
                  have to disclose information relating to or identifying
                  another person, we may need to obtain the consent of that
                  person, if possible.
                </p>
                <p>
                  If we cannot obtain consent, we may need to withhold that
                  information or edit the data to remove the identity of that
                  person, if possible. There are certain types of data which we
                  are not obliged to disclose to you, which include personal
                  data which records our intentions in relation to any
                  negotiations with you where disclosure would be likely to
                  prejudice those negotiations.
                </p>
                <p>
                  We are entitled to refuse a data access request from you where
                </p>
                <ol type='i'>
                  <li>
                    such request is manifestly unfounded or excessive, in
                    particular because of its repetitive character (in this
                    case, if we decide to provide you with the personal data
                    requested, we may charge you a reasonable fee to account for
                    administrative costs of doing so)
                  </li>
                  <li>
                    or we are entitled to do so pursuant to Data Protection
                    Legislation.
                  </li>
                </ol>
                <h5>
                  Right to update your personal data or correct any mistakes in
                  your personal data
                </h5>

                <p>
                  {' '}
                  You can require us to correct any mistakes in your personal
                  data which we hold free of charge. If you would like to do
                  this, please:{' '}
                </p>
                <ul>
                  <li>
                    email, call or write to us (see ‘How can you contact us’
                    below)
                  </li>
                  <li>
                    let us have enough information to identify you (e.g. name,
                    registration details); and
                  </li>
                  <li>
                    let us know the information that is incorrect and what it
                    should be replaced with.
                  </li>
                </ul>
                <p>
                  If we are required to update your personal data, we will
                  inform recipients to whom that personal data have been
                  disclosed (if any), unless this proves impossible or has a
                  disproportionate effort. It is your responsibility that all of
                  the personal data provided to us is accurate and complete. If
                  any information you have given us changes, please let us know
                  as soon as possible (see ‘How to contact us’ below).
                </p>
                <h5>Right to ask us to stop contacting you</h5>
                <p>
                  You can ask us to stop contacting you. If you would like to do
                  this, please:
                </p>
                <ul>
                  <li>
                    email, call or write to us. Details can be found at this
                    link.
                  </li>
                  <li>
                    You can also click on the ‘unsubscribe’ button at the bottom
                    of any email sent to you from us. It may take up to 5 days
                    for this to take place
                  </li>
                  <li>
                    let us know what method of contact you are not happy with if
                    you are unhappy with certain ways of contacting you only
                    (for example, you may be happy for us to contact you by
                    email but not by telephone).
                  </li>
                </ul>
                <p>
                  We will provide you with information on action taken on a
                  request to stop contacting you - this may be in the form of a
                  response email confirming that you have ‘unsubscribed’.
                </p>
                <h5>
                  Right to restrict or prevent processing of personal data
                </h5>
                <p>
                  In accordance with Data Protection Legislation, you may
                  request that we stop processing your personal data temporarily
                  if:
                </p>
                <ul>
                  <li>
                    {' '}
                    you do not think that your data is accurate (but we will
                    start processing again once we have checked and confirmed
                    that it is accurate){' '}
                  </li>
                  <li>
                    the processing is unlawful but you do not want us to erase
                    your data
                  </li>
                  <li>
                    we no longer need the personal data for our processing, but
                    you need the data to establish, exercise or defend legal
                    claims or
                  </li>
                  <li>
                    you have objected to processing because you believe that
                    your interests should override the basis upon which we
                    process your personal data.
                  </li>
                </ul>

                <p>
                  If you exercise your right to restrict us from processing your
                  personal data, we will continue to process the data if:
                </p>
                <ul>
                  <li> you consent to such processing;</li>
                  <li>
                    {' '}
                    the processing is necessary for the exercise or defence of
                    legal claims;
                  </li>
                  <li>
                    {' '}
                    the processing is necessary for the protection of the rights
                    of other individuals or legal persons; or
                  </li>
                  <li>
                    {' '}
                    the processing is necessary for public interest reasons.
                  </li>
                </ul>

                <h5>Right to data portability</h5>
                <p>
                  In accordance with Data Protection Legislation, you may ask
                  for an electronic copy of your personal data that you have
                  provided to us and which we hold electronically, or for us to
                  provide this directly to another party. This right only
                  applies to personal data that you have provided to us – it
                  does not extend to data generated by us. The right to data
                  portability also only applies where:
                </p>
                <ol>
                  <li>
                    the processing is based on your consent or for the
                    performance of a contract; and
                  </li>
                  <li>the processing is carried out by automated means.</li>
                </ol>

                <h5>Right to erasure</h5>

                <p>
                  In accordance with Data Protection Legislation, you can ask us
                  to erase your personal data where:
                </p>
                <ul>
                  <li>
                    {' '}
                    you do not believe that we need your personal data in order
                    to process it for the purposes set out in this Policy;
                  </li>
                  <li>
                    {' '}
                    if you had given us consent to process your personal data,
                    you withdraw that consent and we cannot otherwise legally
                    process your personal data;
                  </li>
                  <li>
                    {' '}
                    you object to our processing and we do not have any legal
                    basis for continuing to process your personal data;
                  </li>
                  <li>
                    {' '}
                    your data has been processed unlawfully or have not been
                    erased when it should have been; or
                  </li>
                  <li>
                    {' '}
                    the personal data have to be erased to comply with law.
                  </li>
                </ul>

                <p>
                  We may continue to process your personal data in certain
                  circumstances in accordance with Data Protection Legislation.
                  Where you have requested the erasure of your personal data, we
                  will inform recipients to whom that personal data have been
                  disclosed, unless this proves impossible or involves
                  disproportionate effort. We will also inform you about those
                  recipients if you request it.
                </p>

                <h5>Right to complain to the DPC</h5>
                <p>
                  If you do not think that we have processed your personal data
                  in accordance with this Policy, please contact us in the first
                  instance. If you are not satisfied, you can complain to the
                  DPC or exercise any of your other rights pursuant to Data
                  Protection Legislation. Information about how to do this is
                  available on the DPC website at the following link: <a href='https://www.dataprotection.ie' target='_blank' rel='noreferrer'>https://www.dataprotection.ie</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <BottomPage />
      </div>
    </Fragment>
  )
}

export default Privacy
