import React, { Fragment, useState, useEffect } from 'react'
import Spinner from '../../components/Spinner/Spinner'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import Oauth from './OAuth'
import { toast } from 'react-toastify'
import ils_05 from '../../assets/images/assets/ils_05.svg'
import ils_05_1 from '../../assets/images/assets/ils_05_1.svg'
import ils_05_2 from '../../assets/images/assets/ils_05_2.svg'
import ils_05_3 from '../../assets/images/assets/ils_05_3.svg'
import ils_05_4 from '../../assets/images/assets/ils_05_4.svg'
import ils_05_5 from '../../assets/images/assets/ils_05_5.svg'



const SignIn = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    userEmail: '',
    userPassword: ''
  })

  

  {/* ---- end oAuth ---- */}
  const { userEmail, userPassword } = formData

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  const onSubmit = async e => {
    e.preventDefault()
    
    try {
      const auth = getAuth()
      const userCredential = await signInWithEmailAndPassword(
        auth,
        userEmail,
        userPassword
      )

      if (userCredential.user) {
        navigate('/admin/dashboard')
        if (userCredential.user.displayName === null) {
          toast.success('Welcome Admin')
        } else {
          toast.success(`Welcome ${userCredential.user.displayName}`)
        }
      }
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      console.log(error)
      const userNotFound = 'User Not Found'
      const incorrectPassword = 'Incorrect Password'
      if (errorCode === 'auth/user-not-found') {
        toast.error(userNotFound)
      }
      if (errorCode === 'auth/wrong-password') {
        toast.error(incorrectPassword)
      } else {
        console.log(errorMessage)
      }
    }
  }

  if (loading) {
    return <Spinner />
  }
  return (
    <Fragment>
      <div className='fancy-feature-two position-relative mt-200 lg-mt-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-5 col-lg-6 col-md-7 ms-auto'>
              <div className='block-style-two' data-aos='fade-left'>
                <div className='title-style-one'>
                  <div className='sc-title-three'>Admin</div>
                  <h2 className='main-title'>Welcome</h2>
                </div>
                {/* /.title-style-one */}
                <p className='pt-10 pb-20 lg-pb-10'>
                  Please sign in using your email and password
                </p>
                <div className='form-style-one'>
                  <form onSubmit={onSubmit}>
                    <div className='col-12'>
                      <div className='input-group-meta form-group mb-30'>
                        <label>Email*</label>
                        <input
                          type='email'
                          placeholder='myemail@fleskriverapartments.com'
                          name='userEmail'
                          id='userEmail'
                          value={userEmail}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='input-group-meta form-group mb-30'>
                        <label>Password*</label>
                        <input
                          type='password'
                          placeholder='password'
                          name='userPassword'
                          id='userPassword'
                          value={userPassword}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className='col-12'>
                      <button className='btn-eight ripple-btn'>Sign In</button>
                    </div>
                  
                    <div className='col-12'>
                      <div className='input-group-meta form-group mb-30'>
                        <label>Or Sign In With</label>
                        <div className='pt-10 pb-20 lg-pb-10'>
                          <Oauth />
                          
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className='btn-three rev mt-55 lg-mt-30'>
                  Don't have an account?{' '}
                  <Link to='/admin/sign-up'>
                    Click here <i className=' fas fa-chevron-right' />
                  </Link>
                </div>
              </div>
              {/* /.block-style-two */}
            </div>
          </div>
        </div>
        {/* /.container */}
        <div className='illustration-holder-two sm-mt-40'>
          <img src={ils_05} alt='' className='main-illustration w-100' />
          <img src={ils_05_1} alt='' className='shapes shape-one' />
          <img
            src={ils_05_2}
            alt=''
            className='shapes shape-two'
            data-aos='fade-up'
            data-aos-anchor='.fancy-feature-two'
            data-aos-delay={100}
            data-aos-duration={2000}
          />
          <img
            src={ils_05_3}
            alt=''
            className='shapes shape-three'
            data-aos='fade-up'
            data-aos-anchor='.fancy-feature-two'
            data-aos-delay={150}
            data-aos-duration={2000}
          />
          <img src={ils_05_4} alt='' className='shapes shape-four' />
          <img src={ils_05_5} alt='' className='shapes shape-five' />
        </div>
        {/* /.illustration-holder-two */}
      </div>
      {/* /.fancy-feature-two */}
    </Fragment>
  )
}

export default SignIn
