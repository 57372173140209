import React, { Fragment } from 'react'
import BlockStyleOne from '../../components/BlockStyle/BlockStyleOne'

const FleskServices = () => {
  return (
    <Fragment >
      <div className='fancy-feature-eighteen position-relative pt-200 pb-225 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100' id='homeExplore'>
        <div className='illustration-holder' >
          <div className='shapes oval-one' />
          <div className='shapes oval-two' />
          <div className='shapes oval-three' />
          <div className='shapes oval-four' />
          <div className='shapes oval-five' />
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-xl-5 col-lg-6 col-md-7'>
              <div className='block-style-two' data-aos='fade-left'>
                <div className='title-style-three'>
                  <div className='sc-title-three'>Amenities</div>
                  <h2 className='main-title'>
                    Your <span>Home</span> away from home
                  </h2>
                </div>
                {/* /.title-style-three */}
                <p className='pt-20 pb-25 lg-pb-20'>
                  Our apartments are equipped with all the modern conveniences
                  you need for a home away from home experience; including 2
                  bedrooms, a flat-screen TV, an equipped kitchen with a
                  microwave, oven and a fridge, a washing machine, dishwasher
                  and 2 bathrooms with a shower. Towels and bed linen are
                  provided in the apartment too for your convenience.
                </p>
                <ul className='style-none list-item color-rev'>
                  <li>Free Pitch and Putt.</li>
                  <li>Free Tennis Court access.</li>
                  <li>Adjacent to Killarney National Park.</li>
                </ul>
                <a href='#questions' className='btn-eight mt-50 lg-mt-30'>
                  More Info
                </a>
              </div>
              {/* /.block-style-two */}
            </div>
            <BlockStyleOne />

          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FleskServices
