import React, { Fragment } from 'react'
import AllBlogItems from '../Blog/AllBlogItems'
import MainHeader from '../../../components/header/MainHeader'
import InnerBanner from '../../../components/InnerBanner/InnerBanner'
import BottomPage from '../../inner-pages/BottomPage'


const Blog = () => {
  return (
    <Fragment>
      <div className='main-page-wrapper'>
         <MainHeader />
      <div className='theme-inner-banner'>
          <InnerBanner
            setPage={'/'}
            pageName='Home'
            intro='All Blogs'
            currentPage='Killarney Flesk River Apartment Blog'
          />
        </div>

      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='admin-dashboard-section-title'>
              <AllBlogItems />
            </div>
            
            <div className='bottom-footer p-3'>
              <BottomPage />
            </div>
          </div>
        </div>
      </div>

      </div>
     
    </Fragment>
  )
}

export default Blog
