import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import HeroBannerTwo from '../../components/hero-banner/HeroBannerTwo'
import FleskServices from '../inner-pages/FleskServices'
import UserFeedback from '../inner-pages/UserFeedback'
import BottomPage from '../inner-pages/BottomPage'
import CookieManagement from '../../components/CookieConsent/CookieManagement'
import FAQ from '../inner-pages/FAQ'
import MainHeader from '../../components/header/MainHeader'



function HomePage () {
  
  return (
    <div className='main-page-wrapper'>
      <Helmet>
        <title>Flesk River Apartments - Book your stay today</title>
      </Helmet>
      <MainHeader />

      {/* <TopNavFour /> */}
      {/* ---- Main Nav ---- */}
      <HeroBannerTwo />
      {/* ---- Main Hero Item ---- */}
         
      {/* --- Amenities --- */}
      <FleskServices />

      {/* --- Testimonials --- */}   
      <UserFeedback />

      {/* --- FAQ Page --- */}
      <FAQ/>

      {/* --- Footer --- */}
      <BottomPage />
      <CookieManagement />

    </div>
  )
}

export default HomePage
