import React, { Fragment } from 'react'
import { getAuth } from 'firebase/auth'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthStatus } from '../../hooks/useAuthStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

const Copywrite = () => {
  const { loggedIn, checkingStatus } = useAuthStatus()
  const auth = getAuth()
  console.log('check status', loggedIn)
  const navigate = useNavigate()
 
  const onLogout = () => {
    auth.signOut()
    toast.info('Logged Out', { autoClose: 1000 })
    setTimeout(() => {
      navigate(0)
    }, 500)
    
  }

  return (
    <Fragment>
      <div className='d-lg-flex justify-content-between align-items-center'>
        <ul className='order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none'>
          <li>
            <a href='https://webdesign.patricktangney.com/'>
              Web Design - Creative Ipsum
            </a>
          </li>
          <li className='ml-2 mr-2'>
            {' '}
            <FontAwesomeIcon icon={faMinus} />{' '}
          </li>
          <li>
            {(function () {
              if (!loggedIn) {
                return <Link to='/admin/sign-in' onClick={window.location.reload}>Sign In</Link>
              } else {
                return (
                  <Fragment>
                    <Link
                      className='btn btn-primary text-white'
                      to='/admin/dashboard'
                    >
                      Dashboard
                    </Link>
                    <a
                      href='#'
                      className='btn btn-primary text-white'
                      onClick={onLogout}
                    >
                      Sign Out
                    </a>
                  </Fragment>
                )
              }
            })()}
          </li>
        </ul>
        <p className='copyright text-center order-lg-0 pb-15'>
          Copyright @{new Date().getFullYear()} Flesk River Apartments.
        </p>
      </div>
    </Fragment>
  )
}

export default Copywrite
