import React from 'react'

function SpinnerMessage () {
  return (
    <div id='preloader'>
      <div id='ctn-preloader' className='ctn-preloader'>
        <div className='animation-preloader'>          
          <div className='txt-loading'>
            <span data-text-preloader='S' className='letters-loading'>
              S
            </span>

            <span data-text-preloader='E' className='letters-loading'>
              E
            </span>

            <span data-text-preloader='N' className='letters-loading'>
              N
            </span>

            <span data-text-preloader='D' className='letters-loading'>
              D
            </span>

            <span data-text-preloader='I' className='letters-loading'>
              I
            </span>

            <span data-text-preloader='N' className='letters-loading'>
              N
            </span>

            <span data-text-preloader='G' className='letters-loading'>
              G
            </span>
          </div>
          <div className='txt-loading'>
            <span data-text-preloader=' ' className='letters-loading'></span>
            <span data-text-preloader='M' className='letters-loading'>
              M
            </span>
            <span data-text-preloader='E' className='letters-loading'>
              E
            </span>
            <span data-text-preloader='S' className='letters-loading'>
              S
            </span>
            <span data-text-preloader='S' className='letters-loading'>
              S
            </span>
            <span data-text-preloader='A' className='letters-loading'>
              A
            </span>
            <span data-text-preloader='G' className='letters-loading'>
              G
            </span>
            <span data-text-preloader='E' className='letters-loading'>
              E
            </span>
          </div>
        </div>       
      </div>
    </div>
  )
}

export default SpinnerMessage
