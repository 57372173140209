import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Tilt from 'react-parallax-tilt'
import Slider from '../Slider/Slider'

const HeroBannerTwo = () => {
  return (
    <Fragment>
      <div className="hero-banner-two">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <h1 className="hero-heading mt-4 text-prime">
                  Killarney Flesk River Apartments
                </h1>
                <p className="text-lg pt-25 sm-pt-10">
                  The Flesk River Apartments are ideally located on the Muckross
                  Road in Killarney and have two bedrooms and two bathrooms, a
                  fully equipped kitchen and living room. Our apartments are
                  perfect for a self-catering break and can accommodate up to
                  five people, perfect for family and friends.
                </p>
                <a
                  href="https://secure.killarneyriverapartments.com/"
                  aria-label="book now"
                  target="_blank"
                  rel="noreferrer"
                  className="btn-one ripple-btn"
                >
                  Book Now
                </a>
              </div>
              <div className="col-md-6 order-first order-md-2">
                <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>
                  <Slider />
                </Tilt>
              </div>
            </div>
          </div>
          {/* /.container */}
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.hero-banner-two */}
    </Fragment>
  );
}

export default HeroBannerTwo
