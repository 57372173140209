import React, { Fragment, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './router/AppRouter'
import { Helmet } from 'react-helmet'
import AOS from 'aos'
import 'aos/dist/aos.css'
import ScrollToTop from './components/ScrollToTop'
import ScrollToTopBehavior from './components/ScrollTopBehaviour'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


function App () {
  useEffect(() => {
    AOS.init({
      duration: 1200
    })
  }, [])

  return (
    
      <Fragment>
        <Helmet>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta
            name='keywords'
            content='Killarney, Apartments, Wild Atlantic Way, Family, Co Kerry, Flesk River Apartments'
          />
          <meta
            name='description'
            content='Killarney Flesk River Apartments - Book your stay in Killarney Co Kerry Ireland - Ideal for families who want to get away and explore Killarney and the surrounding National Park - located on the Ring of Kerry and Wild Atlantic Way. Fun activities for all the family to do - stay in one of our apartments that holds up to 5 guests and pet friendly! Your home away from home - Book your stay today, good rates and fully equipped property for all your family needs'
          />
          <meta
            property='og:site_name'
            content='Killarney Flesk River Apartment'
          />
          <meta
            property='og:url'
            content='https://killarneyfleskriveraparments.com'
          />
          <meta property='og:type' content='website' />
          <meta
            property='og:title'
            content='Killarney Flesk River Apartments'
          />
        </Helmet>
        {/* {End Seo Helmet}
         */}

        <ScrollToTop />

        <BrowserRouter>
          <ScrollToTopBehavior />
          <ToastContainer  />
          <AppRouter />
        </BrowserRouter>
      </Fragment>
  )
}

export default App
