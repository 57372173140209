import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { LinkContainer } from 'react-router-bootstrap'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBlog, faSign } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

const AdminProfile = () => {
  const navigate = useNavigate()

  const auth = getAuth()
  const ProfileOptions = [
    {
      name: 'View Profile',
      icon: faBlog,
      routerPath: '/admin/view-profile'
    },
    {
      name: 'View Dashboard',
      icon: faBlog,
      routerPath: '/admin/dashboard'
    }
  ]

  const onLogout = () => {
    auth.signOut()
    toast.info('Logged Out', { autoClose: 1000 })
    setTimeout(() => {
      navigate(0)
    }, 500)
  }
  return (
    <NavDropdown title='Profile' id={`offCan`}>
      {ProfileOptions.map((val, i) => (
        <LinkContainer key={i} to={val.routerPath}>
          <NavDropdown.Item>
            <FontAwesomeIcon icon={val.icon} />
            <span className='ml-1'>{val.name}</span>
          </NavDropdown.Item>
        </LinkContainer>
      ))}
      <NavDropdown.Divider />
      <LinkContainer to='/'>
        <NavDropdown.Item onClick={onLogout}>Sign Out</NavDropdown.Item>
      </LinkContainer>
    </NavDropdown>
  )
}

export default AdminProfile
