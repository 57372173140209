import React from 'react'
import Testimonials from '../../components/Testimonials/Testimonials'
import { ReactComponent as ThumbsUp } from '../../assets/images/assets/Thumbs-Up-Silhouette.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import RatingBar from '../../components/Rating/RatingBar'

function UserFeedback () {
  let userFeedbackStyle = 'feedback_slider_four pt-20 lg-pt-50'
  return (
    <div className='feedback-section-five pt-130 lg-pt-100 pb-95 lg-pb-40'>
      <div className='container'>
        <div className='title-style-three text-center' data-aos='fade-up'>
          <div className='sc-title-three'>Testimonials</div>

          <FontAwesomeIcon
            id='testing'
            icon={faComment}
            size={'4x'}
            className='shapes avatar-one'
            color='#02979e'
            style={{
              outlineWidth: '0px'
            }}
          />
          <h2 className='main-title'>
            What our <span>Guests</span> have to say
          </h2>
        </div>
        <div className='block-style-five pt-60 md-pt-20 mt-30'>
          {/* /.title-style-one */}

          <div className='row align-items-center'>
            <div>
              <RatingBar />
            </div>
          </div>
        </div>

        <Testimonials />
      </div>

    
      <ThumbsUp
        width={120}
        height={120}
        className='shapes avatar-three'
        fill='#7736ff'
        style={{
          outlineWidth: '1px'
        }}
      />
    </div>
  )
}

export default UserFeedback
