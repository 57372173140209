import React, { Fragment, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import ReactGA4 from 'react-ga4'

//Private Routes
import PrivateRoute from '../components/Private/PrivateRoute'

//All Home Page Routes
import HomePage from '../views/home-pages/HomePage'
import ContactUs from '../views/home-pages/ContactUs'
import AboutUs from '../views/home-pages/AboutUs'
import BlogPage from '../views/home-pages/Blog'
import Privacy from '../components/Privacy/Privacy'

// Not Found Page
import NotFound from '../views/NotFound'

// Admin Routes
import AdminSignIn from '../views/admin/SignIn'
import AdminSignUp from '../views/admin/SignUp'
import AdminDashboard from '../views/admin/Dashboard'
import CreateBlog from '../components/Blog/CreateBlog'
import BlogManagement from '../components/Blog/BlogManagement'
import EditBlog from '../components/Blog/EditBlog'
import BlogDetails from '../components/Blog/BlogDetails'
import AdminBlogDetails from '../views/admin/Blog/AdminBlogDetails'
import Blog from '../views/admin/Blog/Blog'



const AppRouter = () => {
  ReactGA4.send({ hitType: 'pageview', page: window.location.pathname })

  return (
    <Fragment>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog/:postCategory/' element={<BlogPage />} />
        <Route
          path='/blog/:postCategory/blog-details/:id'
          element={<BlogDetails />}
        />
        <Route
          path='/blog/blog-details/:id'
          element={<BlogDetails />}
        />

        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='*' element={<NotFound />} />

        {/* Admin Routes */}
        <Route path='/admin/sign-in' element={<AdminSignIn />} />
        <Route path='/admin/sign-up' element={<AdminSignUp />} />
        <Route path='/admin/' element={<PrivateRoute />}>
          <Route path='/admin/dashboard' element={<AdminDashboard />} />
          <Route path='/admin/dashboard/blog-details/:id' element={<AdminBlogDetails />} />
          <Route path='/admin/create-post' element={<CreateBlog />} />
          <Route path='/admin/edit-post/:postId' element={<EditBlog />} />
          <Route path='/admin/blog-management' element={<BlogManagement />} />
        </Route>
      </Routes>
    </Fragment>
  )
}

export default AppRouter
