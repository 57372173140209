import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuthStatus } from '../../hooks/useAuthStatus'
import Spinner from '../../components/Spinner/Spinner'

const PrivateRoute = () => {
  const {loggedIn, checkingStatus} = useAuthStatus()
  if(checkingStatus) {
    return <Spinner />
  }
  return loggedIn ? <Outlet /> : <Navigate to='/admin/sign-in' />
}

export default PrivateRoute
