import React, { Fragment } from 'react'
import BlogCategory from '../../components/Blog/BlogCategory'


const BlogPage = () => {
  return (
    <Fragment>
        <BlogCategory />
    </Fragment>
  )
}

export default BlogPage
