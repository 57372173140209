import React, { useState, useEffect, Fragment } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase.config'
import { Helmet } from 'react-helmet'
import Parser from 'html-react-parser'
import Spinner from '../../components/Spinner/Spinner'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import MainHeader from '../../components/header/MainHeader'
import BottomPage from '../../views/inner-pages/BottomPage'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import BannerAd from '../BannerAd/BannerAd'
import Testimonial from '../Testimonials/Testimonials'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

function BlogDetails () {
  const [blog, setBlog] = useState(null)
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const navigate = useNavigate()

  const selectCategory = [
    { value: 'Eat&Drink', label: 'Eat and Drink' },
    { value: 'Families', label: 'Families' },
    { value: 'Explore', label: 'Explore' },
    { value: 'Facilities', label: 'Facilities' }
  ]

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'blogs', params.id)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        console.log('Document data:', docSnap)
        setBlog(docSnap.data())
        console.log('blog details',blog)
        setLoading(false)
      } else {
        // doc.data() will be undefined in this case
        console.error('Error getting document:', docSnap.error)
      }
    }
    fetchListing()
  }, [params.id])

  if (loading) {
    return <Spinner />
  }

  return (
    <main>
      <Fragment>
        <div className='main-page-wrapper'>
        <Helmet>
          <title>Killarney Flesk River Apartments - {`${blog.postTitle}`}</title>
          <meta name='keywords' content={blog.postTag.map((tag, index) => `${index !== blog.postTag.length ? `${tag.value}` : 'No Tags'}`)} />
        </Helmet>
          <MainHeader />
          <div className='theme-inner-banner'>
            <InnerBanner
              setPage={'/blog'}
              pageName={'Blog'}
              intro={blog.postTitle}
              currentPage='Blog Post'
            />
          </div>

          <div className='blog-details mb-150 lg-mb-100'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-xxl-10 m-auto'>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <article className='blog-meta-three tran3s mt-45'>
                        <Swiper
                          // install Swiper modules
                          modules={[Navigation, Pagination, Scrollbar, A11y]}
                          centeredSlides={true}
                          centeredSlidesBounds={true}
                          spaceBetween={50}
                          navigation
                          onSwiper={swiper => console.log(swiper)}
                          onSlideChange={() => console.log('slide change')}
                        >
                          {blog.imgUrls.map((images, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div
                                  className='swiperSlideDiv'
                                  style={{
                                    backgroundImage: `url(${blog.imgUrls[index]})`,
                                    backgroundSize: 'cover',
                                    height: '500px',
                                    width: '100%',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',

                                    
                                  }}
                                ></div>
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                        <div className='post-data'>
                          <div className='post-tag'>
                            {/* ---- TODO - convert these into link  ---- */}
                            <a href='#'></a>- {blog.postDuration}
                          </div>

                          <div>{Parser(blog.content)}</div>
                        </div>
                      </article>
                       {/* Bottom Tag Widget */}
                       <div className='bottom-widget d-sm-flex align-items-center justify-content-between'>
                          <div className='post-tag'>
                            <ul className='d-flex tags style-none pt-10 align-items-center flex-wrap'>
                            <h6 className='mr-1 py-2'>Tag:</h6>
                            {
                              blog.postTag.map((tag, index) => {                                
                                return (
                                  <li className='mr-2 bg-theme' key={index}>
                                    <a href={'https://www.google.ie/search?q=' + tag.value} target='_blank' rel='noreferrer'>{tag.value}</a>                                    
                                  </li>
                                )
                              })
                            }             
                            </ul>
                          </div>
                        </div>
                    </div>
                    {/* <!-- End of Blog Details --> */}
                    <div className='col-lg-4 col-md-6'>
                      <div className='blog-sidebar ps-xl-5 ps-lg-3 me-xxl-5 mt-45 md-mt-70'>
                        <div className='sidebar-search mb-45'>
                          {/* <SearchInput /> */}
                        </div>
                        <div className='sidebar-category mb-50'>
                          <h5 className='sidebar-title'>Category</h5>
                          <ul className='clearfix style-none'>
                          {
                              selectCategory.map((tag, index) => {                                                               
                                return (
                                  <li key={index}>
                                    <Link to={`/blog/${tag.value}`}>{tag.label}</Link>
                                  </li>
                                )
                              })
                            }  
                          </ul>
                          {/* <Category /> */}
                        </div>
                        
                        {/* <BannerAd /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BottomPage />
          {/* ---- end main page wrapper ---- */}
        </div>
      </Fragment>
    </main>
  )
}

export default BlogDetails
