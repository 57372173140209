import React, { Fragment, useState, useEffect, useRef, Component } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Select from 'react-select'
import Creatable, { useCreatable } from 'react-select/creatable'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase.config'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build'
import MainHeader from '../../components/header/MainHeader'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../Spinner/Spinner'

const CreateBlog = () => {
  const auth = getAuth()
  const navigate = useNavigate()
  const isMounted = useRef(true)
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    postTag: [{ value: 'Eat and Drink', label: 'Eat and Drink' }],
    postDuration: '',
    postTitle: '',
    postCategory: [{ value: 'Eat and Drink', label: 'Eat and Drink' }],
    content: '',
    images: {},
    fade: 'fade-up',
    dataDelay: '100'
  })
  const {
    postTag: postTag,
    postDuration,
    postTitle,
    postCategory: postCategory,
    content,
    images,
    fade,
    dataDelay
  } = formData

  const selectOptions = [
    { value: 'Eat&Drink', label: 'Eat and Drink' },
    { value: 'Families', label: 'Families' },
    { value: 'Explore', label: 'Explore' },
    { value: 'Facilities', label: 'Facilities' }
  ]
  const selectTagOptions = [
    { value: 'Eat&Drink', label: 'Eat and Drink' },
    { value: 'Families', label: 'Families' },
    { value: 'Explore', label: 'Explore' },
    { value: 'Facilities', label: 'Facilities' }
  ]

  const handleChangeSelectOption = (selectOptions, event) => {
    console.log(`Option selected:`, selectOptions.value)
    setFormData(prevState => ({
      ...prevState,
      postCategory: selectOptions
    }))
  }
  const handleChangeSelectTagOption = (field, value) => {
    const loopTags = () => {
      const tags = []
      console.log(tags)
      // forEach loop so that we can remove the __isNew__ property | firebase won't upload with it
      value.forEach(tag => {
        tags.push({ value: tag.value, label: tag.label })
      })
      return tags
    }   

    switch (field) {
      case 'selectTagOptions':
        setFormData(prevState => ({
          ...prevState,
          postTag: loopTags()
        }))
        break
      default:
        break
    }
  }

  const handleEditorState = (event, editor) => {
    const data = editor.getData()
    setFormData({ ...formData, content: data })
  }

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, user => {
        // console.log(auth)
        if (user) {
          setFormData({ ...formData, userRef: user.uid })
        } else {
          navigate('/admin/sign-in')
        }
      })
    }
    return () => {
      isMounted.current = false
    }
  }, [isMounted])

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    // Store image in firebase
    const storeImage = async image => {
      return new Promise((resolve, reject) => {
        const storage = getStorage()
        const fileName = `${auth.currentUser.uid}-${image}-${uuidv4()}`
        const storageRef = ref(storage, 'image/' + fileName)
        const uploadTask = uploadBytesResumable(storageRef, image)

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
              default:
                break
            }
          },
          error => {
            reject(error)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
              resolve(downloadURL)
            })
          }
        )
      })
    }

    const imgUrls = await Promise.all(
      [...images].map(image => storeImage(image))
    ).catch(() => {
      setLoading(false)
      toast.error('Images not uploaded')
      return
    })

    const formDataCopy = {
      ...formData,
      imgUrls,
      timestamp: serverTimestamp()
    }

    delete formDataCopy.images
    delete formDataCopy.address

    const docRef = await addDoc(collection(db, 'blogs'), formDataCopy)
    setLoading(false)
    toast.success('Post Created')

    navigate(
      `/blog/${formDataCopy.postCategory.value}/blog-details/${docRef.id}`
    )
  }

  const onMutate = e => {
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }

    // Files
    if (e.target.files) {
      setFormData(prevState => ({
        ...prevState,
        images: e.target.files
      }))
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData(prevState => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <Fragment>
      <div className='main-page-wrapper'>
        <MainHeader />
        <div className='theme-inner-banner'>
          <InnerBanner
            setPage={'/admin/dashboard'}
            pageName='Dashboard'
            intro='Create New Blog Post'
            currentPage='Create Post'
          />
        </div>
        <div className='blog-section-three mt-4 pt-90 mb-150 lg-pt-40 lg-mb-100'>
          <div className='container'>
            <form id='create-post' onSubmit={onSubmit}>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='input-group-meta form-group mb-30'>
                    <label htmlFor='postTitle'>Title </label>
                    <input
                      type='text'
                      className='form-control'
                      id='postTitle'
                      value={postTitle}
                      onChange={onMutate}
                      placeholder='Enter Title'
                      required
                    />
                  </div>
                </div>          
                <div className='col-lg-12'>
                  <div className='input-group-meta form-group mb-30'>
                    <label htmlFor='postCategory'>Post Category</label>
                    <Select
                      options={selectOptions}
                      onChange={handleChangeSelectOption}
                      placeholder='Select Category ....'
                    />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='input-group-meta form-group mb-30'>
                    <label htmlFor='postTag'>SEO Keywords</label>
                    <Creatable
                      
                      isMulti
                      placeholder='Select Post Tag or Create New Tag'
                      options={selectTagOptions}
                      onChange={value =>
                        handleChangeSelectTagOption('selectTagOptions', value)
                      }
                    />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='input-group-meta form-group mb-30'>
                    <label htmlFor='postDuration'>Post Duration</label>
                    <input
                      type='text'
                      className='form-control'
                      id='postDuration'
                      placeholder='4 minute read'
                      value={postDuration}
                      onChange={onMutate}
                      required
                    />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='input-group-meta form-group mb-30'>
                    <label htmlFor='postDuration'>Image Upload</label>
                    <input
                      className='formInputFile '
                      type='file'
                      id='images'
                      onChange={onMutate}
                      max='6'
                      accept='.jpg,.png,.jpeg,.gif,.bmp,.webp'
                      multiple
                      required
                    />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='input-group-meta form-group mb-30'>
                    <label htmlFor='content'>Content</label>
                    <CKEditor
                      editor={Editor}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('My Editor is ready to use!', editor)
                      }}
                      config={{
                        toolbar: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'link',
                          'bulletedList',
                          'numberedList',
                          '|',
                          'outdent',
                          'indent',
                          '|',
                          'blockQuote',
                          'insertTable',
                          'mediaEmbed',
                          'undo',
                          'redo'
                        ]
                      }}
                      onChange={handleEditorState}
                    />
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='primaryButton btn-eight ripple-btn'
              >
                Create Post
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CreateBlog
