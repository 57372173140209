import React, { Fragment, useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import MainHeader from '../../components/header/MainHeader'
import BottomPage from '../../views/inner-pages/BottomPage'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { db } from '../../firebase.config'
import {
  collection,
  doc,
  deleteDoc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import Spinner from '../Spinner/Spinner'
import Table from 'react-bootstrap/Table'

const BlogManagement = () => {
  const [blog, setBlog] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const params = useParams()
  console.log(params)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const docRef = collection(db, 'blogs')
        const docSnap = await getDocs(docRef)
        console.log('all blogs', docSnap)

        const queryData = query(docRef,
          orderBy('timestamp', 'desc'))

        const querySnap = await getDocs(queryData)
        const blog = []
        querySnap.forEach(doc => {
          console.log(doc.data())
          return blog.push({
            id: doc.id,
            data: doc.data()
          })
        })
        setBlog(blog)
        console.log('this blogs', blog)
        setLoading(false)
      } catch (error) {
        toast.error('Could not fetch listings')
        console.log(error)
      }
    }
    fetchListing()
  }, [])

  const DateConvertor = props => {
    const seconds = new Date(props * 1000)
    return seconds.toLocaleDateString()
  }

  {
    /* ---- Delete Functions ---- */
  }

  function simulateNetworkRequest () {
    return new Promise(resolve => setTimeout(resolve, 2000))
  }

  function DeleteButton (props, item) {
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
      if (isLoading) {
        simulateNetworkRequest().then(() => {
          setLoading(false)
        })
      }
    }, [isLoading])

    const handlePopup = id => {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this blog?'
      )
      if (confirmDelete) {
        handleClick(id)
      } else {
        return
      }
    }

    const handleClick = async id => {
      setLoading(true)

      await deleteDoc(doc(db, 'blogs', id))
        .then(response => {
          console.log('response', response)
          toast.success('Blog deleted successfully')
          setBlog(blog.filter(item => item.id !== id))
        })
        .catch(error => {
          toast.error('Could not delete record')
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return (
      <Button
        confirm={props.confirm}
        search={props.search}
        variant={props.variant}
        onClick={() => handlePopup(props.search)}
      >
        {isLoading ? 'Deleting…' : 'Delete Record'}
      </Button>
    )
  }

  return (
    <div className='main-page-wrapper'>
      {loading ? (
        <Spinner />
      ) : blog && blog.length > 0 ? (
        <Fragment>
          <MainHeader />
          <div className='theme-inner-banner'>
            <InnerBanner
              setPage={'/admin/dashboard'}
              pageName={'Dashboard'}
              intro={'Blog Management'}
              currentPage='Manage'
            />
          </div>

          <div className='blog-section-three pt-90 mb-150 lg-pt-40 lg-mb-100'>
            <div className='container'>
              <Table striped bordered hover variant='light' responsive='lg'>
                <thead>
                  <tr>
                    <th>Blog ID</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Date Created</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {blog.map(
                    item => (
                      console.log(item),
                      (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.data.postTitle}</td>
                          <td>{item.data.postCategory.label}</td>
                          <td>{DateConvertor(item.data.timestamp.seconds)}</td>
                          <td>
                            <Button variant='dark'>
                              <Link
                                to={`/admin/dashboard/blog-details/${item.id}`}
                              >
                                View Post
                              </Link>
                            </Button>
                          </td>
                          <td>
                            <DeleteButton variant={'danger'} search={item.id} />
                            {/* <Button variant='danger' onClick={() => deleteRecord(item.id)}>Delete</Button>                             */}
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Fragment>
      ) : (
        <p>No Blogs fround for {params.postCategory}</p>
      )}
      <BottomPage />
    </div>
  )
}

export default BlogManagement
