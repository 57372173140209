import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Logo from '../../assets/images/river-apartments/compressed/logo.webp'
import CanvasNavigation from './CanvasNavigation'
import AdminNavTwo from './AdminNavTwo'
import { useAuthStatus } from '../../hooks/useAuthStatus';



function MainNavigation () {
  const { loggedIn, checkingStatus } = useAuthStatus()
 
  const expand = 'lg'
  return (
    <>
      <Navbar key={expand} bg='white' expand={expand} className='mb-3'>
        <Container fluid>
          <LinkContainer to={'/'}>
            <Navbar.Brand href='/'>
              <img
                src={Logo}
                width='150'
                height='45'
                className='d-inline-block align-top mr-5'
              />
            </Navbar.Brand>
          </LinkContainer>
          {/* ---- ./logo ---- */}          
          {loggedIn ? <AdminNavTwo /> : <CanvasNavigation />}          
        </Container>
      </Navbar>
    </>
  )
}

export default MainNavigation
