// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSCqNvZgxfQUoPUqBMiQBA_XvTxcqMCXU",
  authDomain: "fleskriverapartments.firebaseapp.com",
  databaseURL: "https://fleskriverapartments-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "fleskriverapartments",
  storageBucket: "fleskriverapartments.appspot.com",
  messagingSenderId: "310402339534",
  appId: "1:310402339534:web:b794e29efa3dd2df8ef69f",
  measurementId: "G-RHHCDYZHEP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore();