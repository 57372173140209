import React, { Fragment, useState } from 'react'
import emailjs from 'emailjs-com'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import SpinnerMessage from '../Spinner/MessageSend'

const ContactForm = () => {
  const [loading, setLoading] = useState(false)

  //form validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is Required'),
    email: Yup.string()
      .required('Email is Required')
      .email('Entered value does not match email format'),
    message: Yup.string().required('Please, leave us a message.')
  })

  const formOptions = {
    resolver: yupResolver(validationSchema)
  }
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState
  const SERVICE = process.env.REACT_APP_EMAILJS_SERVICE_ID
  const TEMPLATE = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
  const USERID = process.env.REACT_APP_EMAILJS_USER_ID
    

  function onSubmit (data, e) {
    //display form data on success
    e.preventDefault()
    setLoading(true)    
    emailjs
      .sendForm(SERVICE,TEMPLATE,e.target,USERID)
      .then(
        function (response) {
          if(response.status === 200) {
            setLoading(false)
            toast.success('Message Sent')
          }
          console.log('SUCCESS!', response.status, response.text)
        },
        function (err) {
          setLoading(false)
          toast.error('Message Failed to Send')
          console.log('FAILED...', err)
        }
      )
    console.log('Message submited: ' + JSON.stringify(data))
    e.target.reset()
  }

  if (loading) {
    return <SpinnerMessage />
  }

  return (
    <Fragment>
      <form id='contact-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'> 
          <div className='col-12'>
            <div className='input-group-meta form-group mb-30'>
              <label>Name*</label>
              <input
                type='text'
                placeholder='Name'
                name='userName'
                {...register('name')}
                className={`${errors.name ? 'is-invalid' : ''}`}
              />
              {errors.name && (
                <div className='invalid-feedback'>{errors.name?.message}</div>
              )}
            </div>
          </div>
          <div className='col-12'>
            <div className='input-group-meta form-group mb-30'>
              <label>Email*</label>
              <input
                type='text'
                placeholder='myemail@email.com'
                name='userEmail'
                {...register('email')}
                className={`${errors.email ? 'is-invalid' : ''}`}
              />
              {errors.name && (
                <div className='invalid-feedback'>{errors.email?.message}</div>
              )}
            </div>
          </div>
          <div className='col-12'>
            <div className='input-group-meta form-group mb-30'>
              <textarea
                placeholder='Your message*'
                name='sendMessage'
                {...register('message')}
                className={`${errors.message ? 'is-invalid' : ''}`}
              />
              {errors.message && (
                <div className='invalid-feedback'>
                  {errors.message?.message}
                </div>
              )}
            </div>
          </div>
          <div className='col-12'>
            <input
              type='Submit'
              placeholder="Submit Message"
              className='btn-eight ripple-btn'
            />
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default ContactForm
