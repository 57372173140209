import React,{Fragment} from 'react';
import { faQuoteRight, faCalendar, faBed, faPeopleGroup,  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialContent = [
    {
        id: 1,
        desc: `These apartments are spacious and really nice and quiet. We had a baby with us and it was ideal. We could go to the Brehon for dinner and use the gleneagle facilities. Brilliant.`,
        title: 'Excellent apartment and facilities around',
        dateReviewed: '12 June',
        reviewerName: 'Laura',
        groupType: 'Family',
        duration: '2 Nights - June 2022'
    },
    {
        id: 2,
        desc: `very clean and tidy, spacious apartment ideal for families. Will definitely return`,
        title: 'Exceptional',
        dateReviewed: '8 June',
        reviewerName: 'Cathy',
        groupType: 'Family',
        duration: '2 Nights - June 2022'
    },
    {
        id: 3,
        desc: `Everything, myself and my family loved it. It was all the little extras, milk etc in fridge and unbelievably clean !`,
        title: 'Exceptional',
        dateReviewed: '22 May',
        reviewerName: 'Cathy',
        groupType: 'Family',
        duration: '3 Nights - May 2022'
    },
]

const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
};


const Testimonial = () => {
    
   
    return ( 
    <Fragment> 
        <Slider className='feedback_slider_four pt-20 lg-pt-50' {...settings}>
            {TestimonialContent.map((val, i) => (
                <div key={i} className="item">
                    <div className="row">
                        <div className="col-xxl-9 col-xl-10 col-lg-8 m-auto">
                            <div className="feedback-block-four mb-80 ms-xxl-4 me-xxl-4">
                            <FontAwesomeIcon icon={faQuoteRight} size='3x' color='--text-color' />
                                <p className='fs-1 fw-bold'>{val.title}</p>
                                <div className="cp-info">
                                    <p>{val.desc}</p>
                                    <span>{val.reviewerName} - </span>
                                    <span>{val.dateReviewed} </span><br />
                                    <span>{val.duration}</span>
                                </div>
                            </div>
                            {/* /.feedback-block-four */}
                        </div>
                    </div>
                </div>
            ))}
        </Slider> 
    </Fragment>
  )
}


export default Testimonial