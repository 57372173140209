import React from 'react'
import CallToAction from '../../components/CallToAction/CallToAction'
import Copywrite from '../../components/Copywrite/Copywrite'
import Footer from '../../components/Footer/Footer'

function BottomPage () {
  return (
    <div className='footer-style-four theme-basic-footer'>
      <div className='container'>
        <div className='inner-wrapper'>
          <div className='subscribe-area'>
            <CallToAction />
          </div>
          {/* /.subscribe-area */}
          <Footer /> {/* /.Footer End */}
          <div className='bottom-footer'>
            <Copywrite />
          </div>
        </div>
        {/* /.inner-wrapper */}
      </div>
    </div>
  )
}

export default BottomPage
