import React, { Component } from 'react'
// import the component
import Mailchimp from 'react-mailchimp-form'

class NewsLetterForm extends Component {
  render () {
    return (
      <Mailchimp
        action={process.env.REACT_APP_MAILCHIMP}
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email Address . .',
            type: 'email',
            required: true
          }
        ]}
        // Change predetermined language
        messages={{
          sending: 'Sending...',
          success: 'Thank you for subscribing!',
          error: 'An unexpected internal error has occurred.',
          empty: 'You must write an e-mail address',
          duplicate: 'Too many subscribe attempts for this email address',
          button: 'Subscribe!'
        }}
        // Add a personalized class
        // className='<YOUR_CLASSNAME>'
      />
    )
  }
}

export default NewsLetterForm
