import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import ContactBlock from '../../components/BlockStyle/ContactBlock'
import MainHeader from '../../components/header/MainHeader'
import BottomPage from '../inner-pages/BottomPage'
import ContactForm from '../../components/ContactForm/ContactForm'

const ContactUs = () => {
  return (
    <Fragment>
      <div className='main-page-wrapper'>
        <Helmet>
          <title>Flesk River Apartments - Get in Touch</title>
        </Helmet>
        <MainHeader />

        <div className='theme-inner-banner'>
          <InnerBanner setPage={'/'} pageName={'Home'} intro='Get in Touch' currentPage='Contact Us' />
        </div>
        <div className='contact-section-one mb-170 lg-mb-120'>
          <div className='container'>
            <ContactBlock />
          </div>
          <div className='mt-100 lg-mt-70'>
            <div className='container'>
              <div className='row gx-xxl-5'>
                <div className='col-lg-6 d-flex order-lg-last'>
                  <div className='form-style-one'>
                    <h3 className='form-title pb-40 lg-pb-20'>Send Message </h3>
                    <ContactForm />
                  </div>
                  {/* /.form-style-one */}
                </div>
                <div className='col-lg-6 d-flex order-lg-first'>
                  <div className='map-area-one mt-10 me-lg-4 md-mt-50'>
                    <div className='mapouter'>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4907.781606472655!2d-9.505433750844537!3d52.045301698922934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48453d86b31b2491%3A0x9977bafe674f100d!2sFlesk%20River%20Apartments%20Killarney!5e0!3m2!1sen!2sie!4v1656067505768!5m2!1sen!2sie'
                        style={{
                          border: 0
                        }}
                        allowFullScreen
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                      />
                    </div>
                  </div>
                  {/* /.map-area-one */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomPage />
      </div>
    </Fragment>
  )
}

export default ContactUs
