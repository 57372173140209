// import Swiper core and required modules
import React, { useState, useEffect, Fragment } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

import Bedroom1 from '../../assets/images/river-apartments/compressed/1.webp'
import Bedroom2 from '../../assets/images/river-apartments/compressed/2.webp'
import Bedroom3 from '../../assets/images/river-apartments/compressed/3.webp'
import Bedroom4 from '../../assets/images/river-apartments/compressed/4.webp'
import RossCastle from '../../assets/images/river-apartments/Stock/compressed/killarney_ross_castle.webp'
import KillarneyPost from '../../assets/images/river-apartments/Stock/compressed/killarney_postbox.webp'
import JauntingCart from '../../assets/images/river-apartments/Stock/compressed/jaunting_cart.webp'
import TorcWater from '../../assets/images/river-apartments/Stock/compressed/torc_waterfall.webp'
import Beach from '../../assets/images/river-apartments/Stock/compressed/beach_inch.webp'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const Slider = () => {
  
  const sliderImgs = [
    {
      index: 1,
      imgUrls: Bedroom1
    },
    {
      index: 2,
      imgUrls: Bedroom2
    },
    {
      index: 4,
      imgUrls: Bedroom3
    },
    {
      index: 5,
      imgUrls: Bedroom4
    },
    {
      index: 6,
      imgUrls: RossCastle
    },
    {
      index: 7,
      imgUrls: KillarneyPost
    },
    {
      index: 8,
      imgUrls: JauntingCart
    },
    {
      index: 9,
      imgUrls: TorcWater
    },
    {
      index: 10,
      imgUrls: Beach
    }
  ]

  /** @TODO connect this to backend */

  return (
    <Fragment>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        centeredSlides={true}
        centeredSlidesBounds={true}
        spaceBetween={50}
        navigation
        onSwiper={swiper => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        
        
        {sliderImgs.map((images, index) => {
          
          return (
            
            <SwiperSlide key={images.index}>
              <div
                className='swiperSlideDiv'
                style={{
                  backgroundImage: `url(${images.imgUrls})`,
                  backgroundSize: 'cover',
                  height: '450px',
                  width: '100%',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  
                }}
              ></div>
            </SwiperSlide>
          )
        })}
      </Swiper>
     
    </Fragment>
  )
}

export default Slider
