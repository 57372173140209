import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWifi,
  faPlane,
  faTrain,
  faGolfBallTee,
  faGuitar,
  faParking,
  faChild,
  faTableTennis,
  faPaw,
  faHouseCircleCheck
} from '@fortawesome/free-solid-svg-icons'

const ApartmentServices = [
  {
    id: 1,
    title: 'Wifi Available',
    fadeUp: 'fade-up',
    icon: faWifi,
    dataDelay: ''
  },
  {
    id: 2,
    title: 'Airport Nearby',
    fadeUp: 'fade-up',
    icon: faPlane,
    dataDelay: '100'
  },
  {
    id: 3,
    title: 'Train Station',
    fadeUp: 'fade-up',
    icon: faTrain,
    dataDelay: '200'
  },
  {
    id: 4,
    title: 'Pitch and Putt',
    fadeUp: 'fade-up',
    icon: faGolfBallTee,
    dataDelay: '300'
  },
  {
    id: 5,
    title: 'Child Friendly',
    fadeUp: 'fade-up',
    icon: faChild,
    dataDelay: '400'
  },
  {
    id: 6,
    title: 'Parking Onsite',
    fadeUp: 'fade-up',
    icon: faParking,
    dataDelay: '500'
  },
  {
    id: 7,
    title: 'INEC Nearby',
    fadeUp: 'fade-up',
    icon: faGuitar,
    dataDelay: '600'
  },
  {
    id: 8,
    title: 'Tennis Courts',
    fadeUp: 'fade-up',
    icon: faTableTennis,
    dataDelay: '700'
  },
  {
    id: 9,
    title: 'Dog Friendly',
    fadeUp: 'fade-up',
    icon: faPaw,
    dataDelay: '800'
  },
  {
    id: 10,
    title: 'Serviced Property',
    fadeUp: 'fade-up',
    icon: faHouseCircleCheck,
    dataDelay: '900'
  }
]

const BlockStyleOne = () => {
  return (
    <Fragment>
      <div className='col-xl-5 col-lg-6 col-md-7 d-flex justify-content-center flex-wrap ms-auto'>
        {ApartmentServices.map((val, i) => (
          <div id='apartmentServices' className='ml-50 small' key={i}>
            <div data-aos={val.fade} data-aos-delay={val.dataDelay}>
              <div className='block-style-ten color-two text-center tran3s mt-50'>
                <div>
                  <FontAwesomeIcon icon={val.icon} />
                  <p className='fw-bold'>{val.title}</p>
                </div>
              </div>
              {/* /.block-style-ten */}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default BlockStyleOne
