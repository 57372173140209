import React, { Fragment } from 'react'
import { getAuth } from 'firebase/auth'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthStatus } from '../../hooks/useAuthStatus'
import { toast } from 'react-toastify'

const NavBottom = () => {
  const { loggedIn, checkingStatus } = useAuthStatus()
  
  console.log('check status', loggedIn)
  const navigate = useNavigate()
  const auth = getAuth()
   const onLogout = () => {
    auth.signOut()
    toast.info('Logged Out', { autoClose: 1000 })
    setTimeout(() => {
      navigate(0)
    }, 500)
    
  }

  return (
    <Fragment>
      <div className='d-flex justify-content-between align-items-center'>
        <ul className='order-lg-1 d-flex justify-content-center footer-nav style-none ms-auto'>      

          
          <li>
            {(function () {
              if (!loggedIn) {
                return <Link to='/admin/sign-in' onClick={window.location.reload}>Sign In</Link>
              } else {
                return (
                  <Fragment>
                    <Link
                      className='nav-item btn btn-primary text-white p-2 ml-2'
                      to='/admin/dashboard'
                    >
                      Dashboard
                    </Link>
                    <a
                      href='#'
                      className='nav-item btn btn-primary text-white p-2 ml-2'
                      onClick={onLogout}
                    >
                      Sign Out
                    </a>
                  </Fragment>
                )
              }
            })()}
          </li>
        </ul>
        
      </div>
    </Fragment>
  )
}

export default NavBottom
