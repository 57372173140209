import React from 'react'
import FreqAskedQuestion from '../../components/FAQs/FAQ'
import { Link } from 'react-router-dom'


const FAQ = () => {
  return (
    <div className='fancy-feature-seven' id='questions'>
      <div className='container'>
        <div className='row'>
          <div className='col-xxl-5 col-lg-5'>
            <div className='block-style-five md-pb-50' data-aos='fade-right'>
              <div className='title-style-three'>
                <div  className='sc-title-three'>Questions &amp; Answers</div>
                <h2 className='main-title'>Any <span>Questions?</span>Find them here.</h2>
              </div>
              {/* /.title-style-one */}
              <p className='pt-10 pb-15'>
                Haven't found an answer ? Please don't hesitate to <br/>contact us.
              </p>
              <Link to='/contact-us' className='btn-five ripple-btn'>
                Contact us
              </Link>
            </div>
            {/* /.block-style-five */}
          </div>
          <div className='col-lg-7 col-lg-6 ms-auto' data-aos='fade-left'>
            <FreqAskedQuestion />
          </div>
        </div>
      </div>
      {/* /.container */}
     
    </div>
  )
}

export default FAQ
