import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { db } from '../../firebase.config'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { toast } from 'react-toastify'

function Oauth () {
    let userType = 'regular'
    const navigate = useNavigate()
    const location = useLocation()
    const onGoogleClick = async () => {
    try {
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      provider.addScope('https://www.googleapis.com/auth/firebase.readonly')
      provider.addScope('https://www.googleapis.com/auth/analytics.readonly')
      const result = await signInWithPopup(auth, provider)
      const user = result.user
      console.log('user', user)

      //Check for user - if use doesn't exist, create user
      const docRef = doc(db, 'users', user.uid)
      const docSnapshot = await getDoc(docRef)

      if (!docSnapshot.exists()) {
        await setDoc(doc(db, 'users', user.uid), {
            name: user.displayName,
            email: user.email,
            timestamp: serverTimestamp(),
            userType: userType
        })
      }
      navigate('/admin/dashboard')
    } catch (error) {
        toast.error('Could not authorise with Google')
    }
  }

  return (
    <div className='socialLogin'>
      <button className='socialIconDiv' onClick={onGoogleClick}>
        <FontAwesomeIcon icon={faGoogle} />
      </button>
    </div>
  )
}

export default Oauth
